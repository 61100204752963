const en = {
  home: {
    // HoneView.vue
    cancelSubscriptionSuccess: "You are no longer subscribe our newletters.",
    cancelSubscriptionFail: "Your operation is failed. Please try again.",
    // BannerComponent.vue
    slideshowImg: "Image of the slideshow",
    title1: "Xinics",
    title2: "",
    subtitle1: "Plugin Future",
    subtitle2: "Promote smart city by our INNOVATION",
    knowMore: "Know More",
    // IntroComponent.vue
    intro:
      "Xinics is committed to innovative technology, attempting to integrate the latest technology in the road, transportation, and vehicle-related fields, providing solutions to different issues, promoting Hong Kong as a Smart City.  Our three major businesses are Electric Vehicle Charging Solution; On-board Road Inspection Project; and Ride-hailing platform.  ",
    buyNow: "Buy Now",
    youtubeTitle: "YouTube video player",
    detailsTitle: "What special?",
    details1:
      "Safety protection: Built-in safety protection functions for your safety.",
    details2:
      "Affordable price: high cost-effective. Our products can meet the daily need of ev charging with the low cost.",
    details3:
      "Easy to use: It can be connected to a Wi-Fi network and directly use the mobile phone to control the charging device.",
    details4:
      "Independent research and development: patented products, absolutely no falsehood.",
    details5: "",
    // CancelSubscriptionComponent.vue
    cancelSubscriptionTitle1: "Are you sure you want to",
    cancelSubscriptionTitle2: " unsubscribe our newletter?",
    cancelSubscriptionConfirm: "Confirm",
  },
  aboutUs: {
    title: "Xinics",
    subtitle: "Plugin Future",
    intro1Title: "Our Business",
    intro1:
      "Xinics is committed to innovative technology, attempting to integrate the latest technology in the road, transportation, and vehicle-related fields, providing solutions to different issues, promoting Hong Kong as a Smart City.  Our three major businesses are Electric Vehicle Charging Solution; On-board Road Inspection Project; and Ride-hailing platform.",
    intro1_1Title: "Electric Vehicle Charging Solution",
    intro1_1:
      "Xinics is determined to develop a new pattern of electric vehicle charging - a vertically integrated charging solution.  All-in-one from the physical charging station to the customer's mobile phone end.  Achieving power-sharing and effectiveness in order to promote the new energy industry.",
    intro1_2Title: "On-board Road Inspection Solution",
    intro1_2:
      "Xinics revolutionized the road inspection system in Hong Kong by introducing an automative road inspection solution.  It detects road problems instantly, uses big data and artificial intelligence to accurately control the time and scope of road maintenance, minimizing manpower and costs.",
    intro1_3Title: "Ride-hailing Platform",
    intro1_3:
      "Car-sharing is a trend, it makes full use of empty seats, reducing waste of resources and achieving sustainable development which is exactly Xinics's philosophy.  So that we introduced the Ride-hailing Platform, providing services for residents in Hong Kong and China, promoting the interconnection between two places.",
    intro1_3Bottom: "gbataxi.com",
    intro2Title: "Our Philosophy",
    intro2_1Title: "Innovative",
    intro2_1pt1:
      "Think about the future of innovative technology and the new energy industry.",
    intro2_1pt2: "Provide alternative charging methods for electric vehicles.",
    intro2_1pt3: "Reform the current road inspection system.",
    intro2_1pt4: "Provide a new online Ride-hailing platform service.",
    intro2_2Title: "Protect environment",
    intro2_2pt1: "Power-sharing, reducing waste.",
    intro2_2pt2:
      "Dynamical road maintenance scheduling, reducing construction material consumption.",
    intro2_2Bottom: "p360tec.com",
    intro2_3Title: "Sustainable development",
    intro2_3pt1: "Create a more complete electric vehicle ecosystem.",
    intro2_3pt2: "Reduce road maintenance costs.",
    intro2_3pt3:
      "Promote the sustainable connection between Hong Kong and China.",
    intro2_3pt4: "Contribute to society and the environment.",
    intro3Title: "Our Responsibility",
    intro3_1:
      "As a science park incubator, Xinics has been researching and developing advanced technological products. We hopes our products promote the innovative city development and the ideas of smart city. Therefore, we has been adhering to a pragmatic attitude and developing electric vehicle charging business in response to the actual needs of cities and vehicle owners.",
    intro3_2:
      "At present, the electric vehicle industry is booming, and the market urgently needs a kind of electric vehicle charging device that can be popularized quickly. Our charging sockets and charging plugs are designed according to the existing power system, without laying additional cables. Comparing to similar products, our ev charging devices are reduced a lot of installation costs and installation steps, solving the problems of often expensive and complicated processes in related infrastructure projects.",
    intro3_3:
      "In addition, our products meet the actual needs of car owners. In cities, more than 90% of car owners do not drive more than 50 kilometers a day on average. The company's products are sufficient to meet daily needs, and slow charging can effectively proplong battery and in other words, they prolong the life of electric vehicles. Some cities are implementing floating electricity charges, and our products can be set to charge at low-price periods, saving electricity costs for car owners, and even helping balance the peak of power consumption within regional power networks",
    intro3_4:
      "We expects that our products can be spread all over the citys, providing convenient charging facilities for the public, and finally achieving the goal of distributed charging, that is, everyone can charge anytime, anywhere, and everyone can charge at an affordable price.",
  },
  evCharging: {
    title: "Electric Vehicle Charging Solution",
    subtitle: "",
    introTitle: "Product Overview",
    introContent:
      "Xinics EV cloud platform can be charged by different ocpp vehicle charging systems (7kw, 14kw, 44kw, even DC ), or 10A or 16A intelligent power sockets and 60A sliding switch.  We designed an all-in-one charging station with an intelligent charger, switch, and charging gun.  It combines with media communication functions to provide a comprehensive solution to real-time timing, metering, billing, and advertisement revenue.  We also designed a big data cloud platform with management functions on consumption, billing, analysis, etc. providing orderly management to charging stations with limited power capacity and evening load charge can alleviate the impact of extra load from electric vehicles on the power grid.  Our app, mini-program and browser base platforms are running in different areas.",
    ideaTitle: "Product Designs and Features",
    ideaContent1:
      "Although the electric vehicle is a trend, the industry develops slowly due to a lack of related supporting packages.  Xinics has committed to developing and researching charging devices with lower costs and suitable for most users.",
    ideaContent2:
      "Old products always emphasize “fast charging” which requires much higher voltage and current, will greatly reduce the battery life.",
    ideaContent3:
      "In fact, 94% of EV owners in major cities around the world travel less than 50km a day, based on the consumption of 20kwh per 100km, the daily average consumption is 10kwh.  Xinics product 16A Level 2 charger can be fully charged for required power in only 1.5 hours.  We hope everyone can get our products at an affordable price and buyers can make a continuous profit, so that to promote the concept of power-sharing and explore a sustainable charging pattern.",
    solutionTitle: "One-stop Solution",
    device: "Charging device",
    deviceDescription:
      "Xinics focuses and pays attention on the development of the charging device, ensuring safety and scalability.",
    device1Title: "Security, multi-layer protection",
    device1Content:
      "Ensuring safety with multi-layer monitoring functions for temperature, current, and voltage.",
    device2Title: "Chips metering, precise pricing",
    device2Content:
      "Real-time power consumption calculation.  Also support dual calculation for power and time.  ",
    device3Title: "ZigBee / SIM connect network",
    device3Content:
      "Based on wifi / SIM setting, connect the Internet of Things (IoT) with a cloud platform. 24 hours real-time monitoring.",
    client: "Clients",
    clientDescription:
      "Xinics charging solution provides a highly integrated management platform to clients while at the same time ensuring the convenience and freedom of the platform.",
    client1Title: "Low costs",
    client1Content1: "1/20 cost of the same product of other brands.*",
    client1Content2: "Online payment, no extra manpower.",
    client2Title: "Easy installation",
    client2Content:
      "No change on the current power supply system.  Client to set maximum power supply to ensure no overload.",
    client3Title: "Fast break even",
    client3Content: "Expected break even period of only 6 - 9 months.*",
    client4Title: "Free pricing",
    client4Content:
      "Set price based on charging amount and time, also based on different time periods.",
    client5Title: "VIP management",
    client5Content:
      "Categorize users for different pricing, discounts, and functions.",
    client6Title: "Smart power management",
    client6Content:
      "Set the maximum power supply, when it is exceeding, the charging device turns on the queuing mode automatically.",
    client7Title: "Cloud monitoring",
    client7Content1:
      "Manage all devices and collect data by one account and one platform.",
    client7Content2: "",
    client8Title: "Custom development",
    client8Content1: "Print your own logo.",
    client8Content2:
      "More functions can be customized and developed, please contact us for more details.",
    user: "Users",
    userDescription:
      "Only with a few buttons, users can complete the payment process, manage the charging setting and check their charging history.",
    user1Title: "Self-service payment",
    user1Content:
      "No registration, scan QR code to pay by Wechat Pay and Alipay.",
    user2Title: "Real-time monitoring",
    user2Content:
      "Real-time monitoring for charging status including the current, voltage, power, time and EV battery etc.",
    user3Title: "Record tracking",
    user3Content: "Check charging history and other information through APP.",
    user4Title: "Remote operation",
    user4Content: "Mobile phone setting for remote operating.",
  },
  gbaTaxi: {
    title: "Ride-hailing Platform",
    subtitle: "",
    introTitle: "Platform Overview",
    introContent:
      "Car-sharing is a trend, the business is recorded a big success around the world.    As the Central Government promoted the Guangdong-Hong Kong-Macau Greater Bay Area as a major development strategy, Xinics intends to revolutionize the Ride-hailing service.  We hope the platform serves as a bridge of interconnection, providing services to residents between the three places, strengthening commercial and social contact.",
    processTitle: "Milestone",
    processContent: "Please stay tuned.",
  },
  roadInspection: {
    title: "On-board Road Inspection Project",
    subtitle: "",
    introTitle: "Project Overview",
    introContent1:
      'Our Onboard road inspection system uses flowing sensor technology, real-time uploading data to the cloud for analyzing road damages status.  The system aims to change the current road inspection module.  Combined with the "Smart mobility" strategy to enhance the transportation system, promote Hong Kong as a smart city.',
    introContent2:
      "There is no sensor installation at a fixed location is needed, the technology will directly detect the roads in a flowing mode, and even to remote areas. The coverage of road inspection is highly increased.  The solution maximizes road safety, not only simplifying the road damage notification procedures but also preventing potential problems. Our sensor can go deep beneath the road, detecting potential damages by AI and data analysis.  ",
    introContent3:
      'AI and data analysis not only can see potential problems but thoroughly understand the overall road network of Hong Kong and create a road situation map, it also benefits future road planning.  The solution intends to use advanced sensing and data collection method, combined with AI and big data analysis so as to revolutionize the current road maintenance method.  Hong Kong will take a big step towards becoming a "Smart City".',
    introContent4: "",
    introContent5: "",
    futureTitle: "About the Future",
    futureContent1:
      'Innovative technology has become a major part of improving transport construction. With its rapid development, the HK Government has published the first Intelligent Transport Systems (ITS) Strategy and development plan in 2001 and The Hong Kong Smart City Blueprint 2.0 recently, expecting to set up an overall plan for Hong Kong town planning.  One of the main projects is "Smart Travel".',
    futureContent2:
      'There are three strategies of “Smart Travel” including "Smart Transport Infrastructure", "Data Sharing and Analytics" and "Applications and Services".  The "Smart Transport Infrastructure" set up collects big data so as to achieve "Data Sharing and Analytics", the result can be applied and served on government departments and road users (Applications and Services).  The plan also has five visions which are Safe, Informative, Green, Mobile, and Accessible referred to as (SIGMA).  Combining the three strategies and the five visions formed the complete "Smart Travel".',
    processTitle: "Milestone",
    processContent:
      "The project has been submitted to the Government and believed it will happen soon. Please stay tuned.",
  },
  product: {
    // ProductView.vue
    company: "Xinics",
    // GoToShoppingCartComponent.vue
    goToShoppingCartConfirm: "Go to shopping cart",
    // PaymentConirmComponent.vue
    purchaseConfirm: "Confirm",
    // ProductComponent.vue
    carouselAlt: "Product Image in Slideshow",
    carouselPrevious: "Previous",
    carouselNext: "Next",
    currencyCode: "USD",
    share: " Share",
    colour: "Color:",
    outOfStock: "Out of stock",
    quantity: "Product Quantity",
    pieces: "pcs",
    purchase: "Purcahse",
    addToShoppingCart: "Add to shopping cart",
    details: "Details",
    specs: "Specs",
    manual: "Manual",
    manualImgText: "User Manual",
    manualDownload: "Click here to download",
    interest: "Product you may interest",
    outOfStockMsg:
      "This product is out-of-stock, but it is in the Presales. Click <a href='/'>here</a> to know more!",
    // ProductInfoComponent.vue
    unitPrice: "Unit Price:",
    totalPrice: "Total Price:",
    quantity2: "Quantity:",
    // ShareComponent.vue
    clipboardHint: "one click to copy",
    ogDescription: "Buy it now!",
    copiedHint: "Copied",
    whatsappAlt: "share this product to whatsapp",
    facebookAlt: "share this product to facebook",
    twitterAlt: "share this product to twitter",
  },
  shoppingCart: {
    // ShoppingCartView.vue
    voucherCodeShortText: "Vocher Code",
    voucherCodeLongText: "Enter Your Vocher Code",
    title: "Shopping Cart",
    currencyCode: "@:product.currencyCode",
    pieces: "@:product.pieces",
    noProduct: "No products added in shopping cart.",
    subTotal: "Sub total",
    voucherCode: "Voucher Code",
    checkOut: "Check out with",
  },
  trackNTrace: {
    // CheckOrderView.vue
    formTitle: "Check Your Order",
    formText: "Please enter your email and order number.",
    email: "Email:",
    emailPlaceholder: "Email you used during checkout",
    invalidEmail: "Invalid Email",
    orderNumber: "Order Number:",
    orderNumberPlaceholder: "Order number",
    invalidOrderNumber: "Invalid Order Number",
    formConfirm: "Confirm",
    // OrderStatusView.vue
    productPhoto: "Product Photo",
    orderId: "Order ID",
    datePaid: "Date Paid",
    shippingAddress: "Shipping Via",
    shippingStatusOrdered: "Ordered",
    shippingStatusShipped: "Shipped",
    shippingStatusOutOfDelivery: "Out of delivery",
    shippingStatusArrived: "Arrived",
  },
  footer: {
    // FooterComponent.vue
    subscriptionSuccess: "Your have subscribed our newletters!",
    subscriptionFailed: "Fail to subscribe our newletters. Please try again.",
    emailSend: "Send",
    emailLoading: "Loading...",
    validEmail: "Success!",
    invalidEmail: "Invalid email or message.",
    subscriptionTitle: "Sign up for Xinics' Newsletter",
    subscriptionText:
      "Sign up to receive exclusive offers and new product directly from Xinics.",
    subscriptionRegister: "Register",
    subscriptionGoogle: "Connect With Google",
    socialMediaTitle: "Follow Us | Social Media",
    socialMediaFacebook: "Our Facebook Page",
    socialMediaInstagram: "Our Instagram Page",
    socialMediaYoutube: "Our Youtube Channel",
    contactUs: "Contact Us",
    emailAddressPlaceholder: "Enter your email...",
    emailContentPlaceholder: "Leave your message here...",
    tel: "Tel",
    email: "Email",
    address: "Address",
    hkaddress:
      "Office 39, 3/F, Building 2W, Phase One, Hong Kong Science Park, Pak Shek Kok, NT, Hong Kong",
    copyright1: "Copyright",
    copyright2: ". All rights reserved.",
  },
  header: {
    // HeaderComponent.vue
    buyNow: "Buy Now",
    shoppingCart: "Shopping Cart",
    orderTracking: "Order Tracking",
    aboutUs: "About Us",
    evCharging: "Electric Vehicle Charging Solution",
    gbaTaxi: "Ride-hailing Platform",
    roadInspection: "On-board Road Inspection Project",
    goToCategory: "Go to previous page",
  },
  shippingAddressForm: {
    // ShippingAddressComponent.vue
    title1: "Shipping Address",
    name: "Name:",
    email: "Email:",
    line1: "Line 1:",
    line2: "Line 2:",
    city: "City:",
    state: "State:",
    country: "Country:",
    postalCode: "Postal Code:",
    hint1: "* required",
    hint2: '^ fill "000000" if you don\'t know / have postal code',
    nextStep: "Next Step",
    title2: "Shipping To ",
    currencyCode: "@:product.currencyCode",
    previousStep: "Previous Step",
    submitLoading: "Loading...",
    submitNormal: "Submit",
    require: "(required)",
    emailNotValid: "(email format is not valid)",
    serverError: "(server error. please try again.)",
  },
  payment: {
    // StripeComponent.vue
    unexpectedError: "An unexpected error occurred.",
    paymentStatusSucceeded: "Payment succeeded!",
    paymentStatusProcessing: "Your payment is processing.",
    paymentStatusRequires: "Your payment was not successful, please try again.",
    paymentStatusError: "Something went wrong.",
    currencyCode: "@:product.currencyCode",
    totalPrice: "Total Price:",
    stripeLoading: "Loading...",
    stripePayNow: "Pay now",
    // PaymentFinishedComponent.vue
    paymentFinishedTitle: "Thank your for your purchase !",
    paymentFinishedId: "Your order ID is ",
    paymentFinishedReceipt:
      "An receipt will been send to {receiptEmail} later.",
    goShopping: "Go shopping",
    saveAsImg: "Save as image",
    tnt: "Trace and Track",
    paymentNotFinished: "Please try again.",
  },
  subscription: {
    // SubscriptionComponent.vue
    formTitle: "Subscribe our newletters",
    formName: "Your name:",
    formEmail: "Your email address:",
    formSubmit: "Submit",
  },
};

const enNumberFormats = {
  currency: {
    style: "currency",
    currency: "USD",
    currencyDisplay: "symbol",
    useGrouping: true,
  },
};

export { en, enNumberFormats };
