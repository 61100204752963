import { createRouter, createWebHistory } from "vue-router";

const HomeView = () => import("../views/home/HomeView.vue");
const AboutUsView = () => import("../views/aboutus/AboutUsView.vue");
const ProductView = () => import("../views/product/ProductView.vue");
const ShoppingCartView = () =>
  import("../views/shoppingcart/ShoppingCartView.vue");
const CheckOrderView = () => import("../views/trackntrace/CheckOrderView.vue");
const OrderStatusView = () =>
  import("../views/trackntrace/OrderStatusView.vue");
const EvChargingView = () => import("../views/evcharging/EvChargingView.vue");
const gbaTaxiView = () => import("../views/gbataxi/gbaTaxiView.vue");
const roadInspectionView = () =>
  import("../views/roadinspection/roadInspectionView.vue");

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/aboutus",
    name: "aboutUs",
    component: AboutUsView,
  },
  {
    path: "/evcharging",
    name: "evCharging",
    component: EvChargingView,
  },
  {
    path: "/gbataxi",
    name: "gbaTaxi",
    component: gbaTaxiView,
  },
  {
    path: "/roadinspection",
    name: "roadInspection",
    component: roadInspectionView,
  },
  {
    path: "/product/:category/:productID",
    name: "product",
    component: ProductView,
  },
  {
    path: "/shoppingcart",
    name: "shoppingCart",
    component: ShoppingCartView,
  },
  {
    path: "/checkorder/:email?/:orderNumber?",
    name: "checkOrder",
    component: CheckOrderView,
  },
  {
    path: "/orderstatus/:email/:orderNumber",
    name: "orderStatus",
    component: OrderStatusView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return { el: to.hash };
    }
  },
});

export default router;
