<script setup>
import store from "./store";
import { provide } from "vue";
import axios from 'axios';

provide('store', store);
store.initializeShoppingCart();

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

axios.defaults.withCredentials = true
axios
  .get(process.env.VUE_APP_API_PATH + '/csrf/')
  .then(() => { axios.defaults.headers.post['X-CSRFToken'] = getCookie('csrftoken') })
</script>

<template>
  <div id="main">
    <router-view />
  </div>
</template>

<style lang="sass">
@import "@/assets/sass/global"

*
  font-family: 'Ubuntu', sans-serif
  font-weight: $regular

body
  margin: 0
  h1, h2, h3, h4 , h5, p
    margin-top: 0

#main
  max-width: $desktop
  width: 100%
  margin-left: auto
  margin-right: auto
</style>
