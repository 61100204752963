import { createI18n } from "vue-i18n";
import { en, enNumberFormats } from "./en";
import { zhHant, zhHantNumberFormats } from "./zh-Hant";

const fallbackLocale = {
  "zh-TW": ["zh-Hant"],
  "zh-HK": ["zh-Hant"],
};

function userLanguage() {
  if (Object.hasOwn(fallbackLocale, window.navigator.language)) {
    return fallbackLocale[window.navigator.language][0];
  }
  return;
}

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem("locale") || userLanguage() || "en",
  fallbackLocale: {
    ...fallbackLocale,
    default: "en",
  },
  numberFormats: {
    en: enNumberFormats,
    "zh-Hant": zhHantNumberFormats,
  },
  messages: {
    en: en,
    "zh-Hant": zhHant,
  },
});

export default i18n;
