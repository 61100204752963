import { reactive } from "vue";

const store = {
  state: reactive({
    navBar: undefined,
    // Add "shoppingCart" variable is to make sure the shopping cart works fine
    // when localstorage is not available
    shoppingCart: undefined,
    orderTracking: undefined,
    currencyDecimal: {
      en: 2,
      "zh-Hant": 0,
    },
  }),
  updateNavBar: function (data) {
    this.state.navBar = data;
  },
  initializeShoppingCart: function () {
    try {
      if (this.state.shoppingCart === undefined) {
        let ls = localStorage.getItem("shoppingCart");
        if (ls !== null) {
          ls = JSON.parse(ls);
          this.state.shoppingCart = ls;
        } else {
          this.state.shoppingCart = {};
        }
      }
    } catch (error) {
      console.error(error);
    }
  },
  // eslint-disable-next-line no-unused-vars
  updateShoppingCart: function (id, quantity) {
    this.state.shoppingCart["id_" + id] = { id: id, quantity: quantity };
    localStorage.setItem(
      "shoppingCart",
      JSON.stringify(this.state.shoppingCart)
    );
  },
  deleteShoppingCartItem: function (id) {
    delete this.state.shoppingCart["id_" + id];
    localStorage.setItem(
      "shoppingCart",
      JSON.stringify(this.state.shoppingCart)
    );
  },
  clearShoppingCart: function () {
    this.state.shoppingCart = {};
    localStorage.removeItem("shoppingCart");
  },
  updateOrderTracking: function (data) {
    this.state.orderTracking = data;
  },
};

export default store;
